import jsonp from 'jsonp';
import queryString from 'query-string';

const accountHandle = 'Territoirecharlevoix.us8',
      userId = '7f27a7d5d1374582c638a0e33',
      listId = 'de3caba05e';

const form = document.getElementById('mailchimp'),
      notices = {
          success: form.querySelector('.l-form__notices .-success'),
          error: form.querySelector('.l-form__notices .-error')
      };

const compactForm = (formData) => {

    const config = {
        u: userId,
        id: listId
    };

    const customFields = {
        EMAIL: formData.EMAIL.value,
        //FNAME: formData.FNAME.value,
        //LNAME: formData.LNAME.value,
        LOCALE: 'fr'
    };

    const merge = {...config, ...customFields};

    return queryString.stringify(merge);
};

const sendForm = (event) => {

    if (event)
        event.preventDefault();

    const baseUrl = `https://${accountHandle}.list-manage.com/subscribe`;
    const data = compactForm(form.elements);
    const url = `${baseUrl}/post-json?${data}`;

    jsonp(url, { param: 'c' }, onResponse);
};

const onResponse = (error, data) => {

    notices.error.innerHTML = '';
    notices.success.innerHTML = '';

    if (data && data.result === 'error') {
        notices.error.innerHTML = data.msg;
    } else {
        notices.success.innerHTML = 'Vous êtes bien inscrit, merci!';
    }
};

const mailchimp = () => {

    form.addEventListener('submit', sendForm, false);
    form.submit = sendForm;

};

export default mailchimp;
