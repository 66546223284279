export class MenuOpener extends HTMLElement {
    constructor() {
        super();

        const button = this.querySelector('button');

        if (!button)
            return;

        this.isOpen = false;
        this.label = this.querySelector('[menu-opener-label]');

        button.addEventListener('click', () => {
            const menu = document.querySelector(this.getAttribute('data-menu'));

            if (menu) {
                menu.toggle(this);
                this.toggle();
                button.blur();
            }
        });
    }

    open() {
        this.isOpen = true;
        this.setAttribute('open', '');
        this.setAttribute('aria-expanded', 'true');
        if (this.label)
            this.label.innerHTML = this.getAttribute('data-label-close');
    }

    close() {
        this.isOpen = false;
        this.removeAttribute('open');
        this.setAttribute('aria-expanded', 'false');
        if (this.label)
            this.label.innerHTML = this.getAttribute('data-label-open');
    }

    toggle() {
        this.isOpen ? this.close() : this.open();
    }
}
customElements.define('menu-opener', MenuOpener);


export class MenuDrawer extends HTMLElement {
    constructor() {

        super();

        this.isOpen = false;
        this.openedBy = null;

        const _ = this;

        this.querySelectorAll('.c-menu__link').forEach(link => {
            link.addEventListener(
                'click',
                this.handleClick.bind(this)
            );
        });

        this.addEventListener('keyup', (event) => {
            console.log('::event', event);
            if (event.code.toUpperCase() === 'ESCAPE') this.hide();
        });

        this.addEventListener('click', (event) => {
            if (event.target.nodeName === 'MENU-DRAWER') this.hide();
        });

    }

    handleClick() {
        this.hide();
        this.openedBy.close();
    }
    show(opener) {
        this.openedBy = opener;

        document.body.classList.add('menu-is-open');
        this.setAttribute('open', '');
        this.isOpen = true;

        //window.App.trapFocus(this, this.querySelector('[role="dialog"]'));
    }

    hide() {

        document.body.classList.remove('menu-is-open');
        this.removeAttribute('open');
        this.isOpen = false;
        //window.App.removeTrapFocus(this.openedBy);
    }

    toggle(opener) {
        this.isOpen ? this.hide() : this.show(opener);
    }
}
customElements.define('menu-drawer', MenuDrawer);
