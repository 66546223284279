import { browserName, osName, isMobile } from 'mobile-device-detect';

const config = {

    browser: {
        name: browserName.toLowerCase().replace(' ', '-'),
        os: osName.toLowerCase().replace(' ', '-'),
    },

    isMobile,//TODO: DUPLICATE ?

    prevRoute: '/',
    routes: {
        default: '/',
        home: '/'
    },

    $html: document.documentElement,
    $body: document.body,

    W : {
        w : window.innerWidth,
        h : window.innerHeight
    },

    isRetina: window.devicePixelRatio >= 1.5,
    isMobile: window.innerWidth <= 768, //TODO: DUPLICATE ?
    isMac: navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC',

};

export default config;
