const element = document.querySelector('.l-header__hero');
const options = {
    rootMargin: '0px 0px -200px 0px'
};

let observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {

        entry.isIntersecting ? document.body.classList.remove('--hero-hidden') : document.body.classList.add('--hero-hidden');

    });
}, options);

observer.observe(element);
