import '../styles/index.scss';

import {resizeEnd} from './utils';
import config from './config';

import {MenuOpener, MenuDrawer} from './components/menu';
import mailchimp from './components/mailchimp';
import './components/header';
import './components/slider';
import './components/form';
import 'lazysizes';

if (process.env.NODE_ENV === 'development') {
    require('../index.html');
}

resizeEnd();

const body = document.body;

body.classList.remove('no-js');

window.addEventListener('resizeEnd', resize());

window.onload = function(event) {
    document.body.classList.remove('-preload');
    mailchimp();
};

function resize() {

    config.$html.style.setProperty('--vh', `${config.W.h / 100}px`);

    config.$html.classList.add(`is-${config.browser.name}`);
    config.$html.classList.add(`is-${config.browser.os}`);

    const header = document.querySelector('[data-fixed-header]');

    if (header)
        config.$html.style.setProperty('--header-height', `${parseInt(header.offsetHeight)}px`);

}
