import Flickity from 'flickity';

// Slider
var flky = new Flickity( '.c-slider', {
    autoPlay: false,
    draggable: '>1',
    lazyLoad: true,
    freeScroll: false,
    prevNextButtons: false,
    pageDots: true,
    resize: true,
    setGallerySize: false,
    watchCSS: false,
    wrapAround: false,
    selectedAttraction: 0.01,
    friction: 0.2
});