const elements = document.querySelectorAll('.l-form__input'),
        className = '-not-empty';

elements.forEach(element => {

    const input = element.querySelector('input'),
          label = element.querySelector('label');
    
    if ( input.value && input.value.length !== 0 )
        element.classList.add(className);

    input.addEventListener('input', event => {
        element.classList.add(className);
    });

    input.addEventListener('change', event => {
        element.classList.add(className);
    });    
    
    input.addEventListener('keydown', event => {
        element.classList.add(className);
    });    
    
    input.addEventListener('paste', event => {
        element.classList.add(className);
    });    
    
    input.addEventListener('focusin', event => {
        element.classList.add(className);
    });
    
    input.addEventListener('focusout', event => {

        if ( input.value.length == 0 )
            element.classList.remove(className);
    });
});